var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _vm.isMobileWidth
          ? _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("div", { staticClass: "card cardcontents" }, [
                  _c("div", { staticClass: "card-header" }, [_vm._v(" Menu ")]),
                  _c("div", { staticClass: "card-body" }, [
                    _c(
                      "ul",
                      { staticClass: "sidebar-my-ol" },
                      _vm._l(_vm.permission_routers, function (item, index) {
                        return _c(
                          "li",
                          { key: index, staticClass: "sidebar-my-menu-item" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "shortcut_a",
                                on: {
                                  click: function ($event) {
                                    return _vm.goMobilePage(item.href)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "sa_mw" }, [
                                  _c(
                                    "i",
                                    {
                                      class: [
                                        "material-icons sidebar-my-menu-icon",
                                        _vm.getIcons(item.icon, "color"),
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.getIcons(item.icon, "icon"))
                                      ),
                                    ]
                                  ),
                                  _c("div", {
                                    staticClass: "sidebar-my-menu-title",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        item.title.replace(" ", "<br/>")
                                      ),
                                    },
                                  }),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
          [
            _c("div", { staticClass: "card cardcontents" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(" 공지사항 "),
                _c(
                  "div",
                  {
                    staticClass: "card-more",
                    on: {
                      click: function ($event) {
                        return _vm.goPage("/sys/notice/noticeManage")
                      },
                    },
                  },
                  [_vm._v("more")]
                ),
              ]),
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("c-table", {
                    ref: "tableNotice",
                    attrs: {
                      tableId: "tableNotice",
                      isTitle: false,
                      isDashboard: true,
                      columnSetting: false,
                      usePaging: false,
                      filtering: false,
                      isFullScreen: false,
                      hideBottom: true,
                      gridHeight: "200px",
                      columns: _vm.gridnotice.columns,
                      data: _vm.gridnotice.data,
                    },
                    on: { rowClick: _vm.rowClickNotice },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
          [
            _c("div", { staticClass: "card cardcontents" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(" 작업허가서 "),
                _c(
                  "div",
                  {
                    staticClass: "card-more",
                    on: {
                      click: function ($event) {
                        return _vm.goPage("/sop/swp/safeWorkPermit")
                      },
                    },
                  },
                  [_vm._v("more")]
                ),
              ]),
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("c-table", {
                    ref: "tableswp",
                    attrs: {
                      tableId: "tableswp",
                      isTitle: false,
                      isDashboard: true,
                      columnSetting: false,
                      usePaging: false,
                      filtering: false,
                      isFullScreen: false,
                      hideBottom: true,
                      gridHeight: "200px",
                      columns: _vm.gridswp.columns,
                      data: _vm.gridswp.data,
                    },
                    on: { rowClick: _vm.rowClickSwp },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
          [
            _c("div", { staticClass: "card cardcontents" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(" 개선관리 "),
                _c(
                  "div",
                  {
                    staticClass: "card-more",
                    on: {
                      click: function ($event) {
                        return _vm.goPage("/sop/ibm/impr")
                      },
                    },
                  },
                  [_vm._v("more")]
                ),
              ]),
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("c-table", {
                    ref: "tableimpr",
                    attrs: {
                      tableId: "tableimpr",
                      isTitle: false,
                      isDashboard: true,
                      columnSetting: false,
                      usePaging: false,
                      filtering: false,
                      isFullScreen: false,
                      hideBottom: true,
                      gridHeight: "200px",
                      columns: _vm.gridimpr.columns,
                      data: _vm.gridimpr.data,
                    },
                    on: { rowClick: _vm.rowClickImpr },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
          [
            _c("div", { staticClass: "card cardcontents" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(" 위험성평가 "),
                _c(
                  "div",
                  {
                    staticClass: "card-more",
                    on: {
                      click: function ($event) {
                        return _vm.goPage("/ram/riskList")
                      },
                    },
                  },
                  [_vm._v("more")]
                ),
              ]),
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("c-table", {
                    ref: "table4m",
                    attrs: {
                      tableId: "table4m",
                      isTitle: false,
                      isDashboard: true,
                      columnSetting: false,
                      usePaging: false,
                      filtering: false,
                      isFullScreen: false,
                      hideBottom: true,
                      gridHeight: "200px",
                      columns: _vm.grid4m.columns,
                      data: _vm.grid4m.data,
                    },
                    on: { rowClick: _vm.rowClick4m },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "q-dialog",
        {
          attrs: { position: "top" },
          model: {
            value: _vm.noticedialog,
            callback: function ($$v) {
              _vm.noticedialog = $$v
            },
            expression: "noticedialog",
          },
        },
        [
          _c(
            "q-card",
            { staticStyle: { "min-width": "800px", width: "800px" } },
            [
              _c(
                "q-card-section",
                { staticClass: "row items-center no-wrap mainpoplayer" },
                [
                  _c(
                    "q-list",
                    {
                      staticClass: "rounded-borders",
                      staticStyle: { width: "100%" },
                      attrs: { bordered: "" },
                    },
                    _vm._l(_vm.popNotices, function (tag) {
                      return _c(
                        "q-expansion-item",
                        {
                          key: tag.sysNoticeId,
                          staticStyle: { "border-bottom": "1px solid #fff" },
                          attrs: {
                            "switch-toggle-side": "",
                            group: "noticepops",
                            "header-class": "bg-orange-7 text-white",
                            "expand-icon-class": "text-white",
                            id: tag.sysNoticeId,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _c("q-item-section", [
                                      _vm._v(
                                        " " + _vm._s(tag.noticeTitle) + " "
                                      ),
                                    ]),
                                    _c(
                                      "q-item-section",
                                      { attrs: { avatar: "" } },
                                      [
                                        _c("q-icon", {
                                          attrs: {
                                            color: "white",
                                            name: "close",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.closePopNotice(
                                                tag.sysNoticeId
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: tag.expanded,
                            callback: function ($$v) {
                              _vm.$set(tag, "expanded", $$v)
                            },
                            expression: "tag.expanded",
                          },
                        },
                        [
                          _c(
                            "q-card",
                            [
                              _c("q-card-section", [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(tag.noticeContents),
                                  },
                                }),
                              ]),
                              tag.cnt > 0
                                ? _c(
                                    "q-card-section",
                                    [
                                      _c("c-upload", {
                                        attrs: {
                                          attachInfo: {
                                            taskClassCd: "NOTICE_VENDOR",
                                            taskKey: tag.sysNoticeId,
                                          },
                                          editable: false,
                                          label: "첨부파일",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "q-card",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: tag.dayViewDisable,
                                  expression: "tag.dayViewDisable",
                                },
                              ],
                            },
                            [
                              _c(
                                "q-card-section",
                                [
                                  _c("q-toggle", {
                                    staticClass: "anymoreNotice",
                                    attrs: {
                                      size: "sm",
                                      "checked-icon": "check",
                                      color: "red",
                                      label: "더이상 보지 않기",
                                      "unchecked-icon": "clear",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.updateTodayChk(
                                          tag.sysNoticeId
                                        )
                                      },
                                    },
                                    model: {
                                      value: tag.dayView,
                                      callback: function ($$v) {
                                        _vm.$set(tag, "dayView", $$v)
                                      },
                                      expression: "tag.dayView",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }