<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" v-if="isMobileWidth">
        <div class="card cardcontents">
          <div class="card-header">
            Menu
          </div>
          <div class="card-body">
            <ul class="sidebar-my-ol">
              <li 
                class="sidebar-my-menu-item"
                v-for="(item, index) in permission_routers"
                :key="index"
                >
                <a class="shortcut_a" @click="goMobilePage(item.href)">
                  <div class="sa_mw">
                    <i :class="['material-icons sidebar-my-menu-icon', getIcons(item.icon, 'color')]">{{ getIcons(item.icon, 'icon') }}</i>
                    <div class="sidebar-my-menu-title" v-html="item.title.replace(' ','<br/>') "></div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="card cardcontents">
          <div class="card-header">
            공지사항
            <div class="card-more" @click="goPage('/sys/notice/noticeManage')">more</div>
          </div>
          <div class="card-body">
            <c-table
              ref="tableNotice"
              tableId="tableNotice"
              :isTitle="false"
              :isDashboard="true"
              :columnSetting="false"
              :usePaging="false"
              :filtering="false"
              :isFullScreen="false"
              :hideBottom="true"
              gridHeight="200px"
              :columns="gridnotice.columns"
              :data="gridnotice.data"
              @rowClick="rowClickNotice"
            >
            </c-table>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="card cardcontents">
          <div class="card-header">
            작업허가서
            <div class="card-more" @click="goPage('/sop/swp/safeWorkPermit')">more</div>
          </div>
          <div class="card-body">
            <c-table
              ref="tableswp"
              tableId="tableswp"
              :isTitle="false"
              :isDashboard="true"
              :columnSetting="false"
              :usePaging="false"
              :filtering="false"
              :isFullScreen="false"
              :hideBottom="true"
              gridHeight="200px"
              :columns="gridswp.columns"
              :data="gridswp.data"
              @rowClick="rowClickSwp"
            >
            </c-table>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="card cardcontents">
          <div class="card-header">
            개선관리
            <div class="card-more" @click="goPage('/sop/ibm/impr')">more</div>
          </div>
          <div class="card-body">
            <c-table
              ref="tableimpr"
              tableId="tableimpr"
              :isTitle="false"
              :isDashboard="true"
              :columnSetting="false"
              :usePaging="false"
              :filtering="false"
              :isFullScreen="false"
              :hideBottom="true"
              gridHeight="200px"
              :columns="gridimpr.columns"
              :data="gridimpr.data"
              @rowClick="rowClickImpr"
            >
            </c-table>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="card cardcontents">
          <div class="card-header">
            위험성평가
            <div class="card-more" @click="goPage('/ram/riskList')">more</div>
          </div>
          <div class="card-body">
            <c-table
              ref="table4m"
              tableId="table4m"
              :isTitle="false"
              :isDashboard="true"
              :columnSetting="false"
              :usePaging="false"
              :filtering="false"
              :isFullScreen="false"
              :hideBottom="true"
              gridHeight="200px"
              :columns="grid4m.columns"
              :data="grid4m.data"
              @rowClick="rowClick4m"
            >
            </c-table>
          </div>
        </div>
      </div>
    </div>
    <q-dialog v-model="noticedialog" position="top">
      <q-card style="min-width: 800px;width: 800px">
        <q-card-section class="row items-center no-wrap mainpoplayer">
          <q-list bordered class="rounded-borders" style="width: 100%">
            <q-expansion-item v-for="tag in popNotices"
              switch-toggle-side
              :key="tag.sysNoticeId"
              group="noticepops"
              header-class="bg-orange-7 text-white"
              style="border-bottom: 1px solid #fff;"
              expand-icon-class="text-white"
              :id="tag.sysNoticeId"
              v-model="tag.expanded">
              <template v-slot:header>
                <q-item-section>
                  {{tag.noticeTitle}}
                </q-item-section>
                <q-item-section avatar>
                  <q-icon color="white" name="close"  @click="closePopNotice(tag.sysNoticeId)"/>
                </q-item-section>
              </template>
              <q-card>
                <q-card-section>
                  <div v-html="tag.noticeContents"/>
                </q-card-section>
                <q-card-section v-if="tag.cnt > 0">
                  <c-upload
                    :attachInfo="{taskClassCd: 'NOTICE_VENDOR', taskKey: tag.sysNoticeId}"
                    :editable="false"
                    label="첨부파일">
                  </c-upload>
                </q-card-section>
              </q-card>
              <q-card v-show="tag.dayViewDisable">
                <q-card-section>
                  <q-toggle
                    size="sm"
                    class="anymoreNotice"
                    v-model="tag.dayView"
                    checked-icon="check"
                    color="red"
                    label="더이상 보지 않기"
                    unchecked-icon="clear"
                    @input="updateTodayChk(tag.sysNoticeId)"
                  />
                </q-card-section>
              </q-card>
            </q-expansion-item>
          </q-list>
        </q-card-section>
      </q-card>
    </q-dialog>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import { mapGetters } from 'vuex'
export default {
  name: 'main-dashboard',
  props: {
  },
  data() {
    return {
      noticedialog: false,
      noticePopUrl: '',
      popNotices: [],
      popRealNotices: 0,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
      isMobileWidth: false,
      gridnotice: {
        listUrl: '',
        columns: [
          {
            name: 'noticeClassName',
            field: 'noticeClassName',
            label: '공지분류',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'noticeTitle',
            field: 'noticeTitle',
            label: '제목',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      gridswp: {
        listUrl: '',
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'workSummary',
            field: 'workSummary',
            label: '작업개요',
            align: 'left',
            sortable: false,
          },
          {
            name: 'swpStepName',
            field: 'swpStepName',
            label: '진행단계',
            align: 'center',
            sortable: false,
          },
          {
            name: 'permitDate',
            field: 'permitDate',
            label: '허가일',
            align: 'center',
            sortable: false,
          },
          {
            name: 'permitTypeName',
            field: 'permitTypeName',
            label: '허가서구분',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
      gridimpr: {
        listUrl: '',
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'ibmTaskTypeName',
            field: 'ibmTaskTypeName',
            label: '업무',
            align: 'center',
            sortable: false
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            sortable: false,
          },
          {
            name: 'improveRequestDeptName',
            field: 'improveRequestDeptName',
            label: '요청부서',
            align: 'center',
            sortable: false,
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            label: '진행단계',
            align: 'center',
            sortable: false
          },
        ],
        data: [],
      },
      grid4m: {
        listUrl: '',
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'assessmentName',
            field: 'assessmentName',
            label: '평가명',
            align: 'left',
            sortable: false,
          },
          {
            name: 'ramStepName',
            field: 'ramStepName',
            label: '진행단계',
            align: 'center',
            sortable: false,
          },
          {
            name: 'assessmentDate',
            field: 'assessmentDate',
            label: '평가기간',
            align: 'center',
            sortable: false
          },
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    window.addEventListener('resize', this.getWindowWidth);
    this.getWindowWidth();
    this.init();
  },
  computed: {
    ...mapGetters([
      'permission_routers',
    ]),
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
  },
  methods: {
    init() {
      this.gridnotice.listUrl = selectConfig.sys.notice.list.url;
      this.noticePopUrl = selectConfig.sys.notice.main.url;
      this.gridswp.listUrl = selectConfig.sop.swp.workPermit.list.url;
      this.gridimpr.listUrl = selectConfig.sop.ibm.improve.list.url;
      this.grid4m.listUrl = selectConfig.ram.assessPlan.list.url;
      this.getNotice();
      this.getNoticePop();
      this.getSwp();
      this.getImpr();
      this.get4M();
    },
    getNotice() {
      this.$http.url = this.gridnotice.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        vendorFlag: 'Y',
        pagelimit: 5,
      }
      this.$http.request((_result) => {
        this.gridnotice.data = _result.data;
      },);
    },
    getSwp() {
      this.$http.url = this.gridswp.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        pagelimit: 5,
        // permitDates: ['',''],
      }
      this.$http.request((_result) => {
        this.gridswp.data = _result.data;
      },);
    },
    get4M() {
      this.$http.url = this.grid4m.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        pagelimit: 5,
        ramTechniqueCd: 'RT00000025',
        // assessmentDates: ['',''],
      }
      this.$http.request((_result) => {
        this.grid4m.data = _result.data;
      },);
    },
    getImpr() {
      this.$http.url = this.gridimpr.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        pagelimit: 5,
        actionVendorCd: this.$store.getters.user.userId,
        actionFlag: 'V',
        overFlag: 'N',
        tempTaskFlag: 'N',
        ibmStepVendorFlag: 'Y'
        // actionCompleteRequestDates: ['',''],
      }
      this.$http.request((_result) => {
        this.gridimpr.data = _result.data;
      },);
    },
    getNoticePop() {
      this.popNotices = [];
      this.popRealNotices = 0;
      this.$http.url = this.noticePopUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        vendorFlag: 'Y',
      }
      this.$http.request((_result) => {
        let mainPopNoticeId = window.localStorage.getItem('mainPopNoticeId');
        if (mainPopNoticeId !== null) {
          let icnt=0;
          this.$_.forEach(_result.data, item => {
            if (mainPopNoticeId.indexOf(item.sysNoticeId) < 0) {
              item.dayViewDisable = true;
              if (icnt == 0) {
                item.expanded = false;
              } else {
                item.expanded = false;
              }
              this.popNotices.push(item);
              this.popRealNotices++;
              icnt++;
            }
          })
          if (this.popNotices.length == _result.data.length) {
            window.localStorage.removeItem('mainPopNoticeId');
          }
        } else {
          this.popNotices = _result.data;
          let icnt=0;
          this.$_.forEach(this.popNotices, item => {
            item.dayViewDisable = true;
            if (icnt == 0) {
              item.expanded = false;
            } else {
              item.expanded = false;
            }
            this.popRealNotices++;
            icnt++;
          })
        }
        if (this.popNotices.length > 0) {
          this.noticedialog = true
        }
      },);
    },
    getNoticeDetail(_title, _content) {
      this.popNotices = [{
        sysNoticeId: '',
        expanded: true,
        dayView: false,
        noticeTitle: _title,
        noticeContents: _content,
        dayViewDisable: false,
      }];
      this.popRealNotices = 1;
      this.noticedialog = true;
    },
    updateTodayChk(_noticeId) {
      let mainPopNoticeId = window.localStorage.getItem('mainPopNoticeId');
      if (mainPopNoticeId === null) {
        window.localStorage.setItem('mainPopNoticeId', _noticeId + ',');
      } else {
        if (mainPopNoticeId.indexOf(_noticeId) < 0) {
          window.localStorage.setItem('mainPopNoticeId', mainPopNoticeId + _noticeId + ',');
        } else {
          window.localStorage.setItem('mainPopNoticeId', mainPopNoticeId.replace(_noticeId + ',',''));
        }
      }

    },
    goPage(_page) {
      this.$comm.movePage(this.$router, _page)
    },
    rowClickNotice(row) {
      this.getNoticeDetail(row.noticeTitle, row.noticeContents);
    },
    rowClickSwp(row) {
      this.popupOptions.title = '작업허가서';
      this.popupOptions.param = {
        sopWorkPermitId: row ? row.sopWorkPermitId : '',
        permitTypeCd: row ? row.permitTypeCd : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    rowClickImpr(row) {
      this.popupOptions.title = '개선 상세'; // 개선 상세
      this.popupOptions.param = {
        sopImprovementId: row ? row.sopImprovementId : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    rowClick4m(row) {
      this.popupOptions.title = '위험성평가 상세'; // 4M 상세
      this.popupOptions.param = {
        ramRiskAssessmentPlanId: row ? row.ramRiskAssessmentPlanId : '',
        ramStepCd: row ? row.ramStepCd : 'RRS0000001',
        returnRemark: row ? row.returnRemark : '',
        ramTechniqueCd: 'RT00000025',
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/riskDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    closePopNotice(_id) {
      if (_id == '') {
        this.noticedialog = false;
      } else {
        if(this.popRealNotices == 1) {
          document.getElementById(_id).remove();
          this.noticedialog = false;
        }
        if (this.popRealNotices > 1) {
          document.getElementById(_id).remove();
          this.popRealNotices = this.popRealNotices - 1;
        }
      }
    },
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;

      if (this.windowWidth < "992") {
        this.isMobileWidth = true;
      } else {
        this.isMobileWidth = false;
      }
    },
    goMobilePage(url) {
      this.$router.push(url);
    },
    getIcons(_icons, _type) {
      if (_type == 'icon') {
        return _icons.split('|')[0]
      } else {
        return _icons.length > 1 ? 'text-' + _icons.split('|')[1] : 'text-grey-10';
      }
    },
  }
};
</script>
<style lang="sass">
  .topmainlayer
    border-radius: 15px
    height: 180px
    background-image: url('../../assets/images/mainback.png')
    display: block
    div.title
      padding: 30px 0px 0px 50px
      font-weight: 600
      color: #0070C0
      font-size: 20px
    .title1
      color: #17375E
      font-size: 25px
    .title2
      color: #17375E
      font-size: 35px
    .title3
      color: #0070C0
      font-size: 35px
    .mainbtns
      float: right !important
      margin-top: -120px
      padding-right: 20px
    .today
      color: #fb8c00
      padding-top: 35px
      padding-left: 23px
      font-weight: 600
  .mainchild
    // margin-top: -75px !important
    padding: 10px 0px 0px 0px
  .maincard
    margin-right: 6px
    margin-bottom: 6px
  // .maincard:hover
  //   margin-top: -10px
  .cardcontents
    margin-top: 6px
    margin-bottom: 6px
    margin-right: 6px
    border-radius: 15px
    .card-body
      padding: 10px !important
    .card-header:before
      font-family: "Material Icons"
      content: "\e876"
    .card-header
      border-radius: 15px 15px 0 0
      height: 40px
      color: #1ab394
      background: #f8f8f8
      display: inline-block
      padding-top: 8px
      text-align: left
      padding-left: 10px
      .card-more
        float: right !important
        cursor: pointer
        color: #d2d2d2
      .card-more:hover
        color: #1ab394
  .maincard .card-body
    background: #fff
    border-radius: 5px
    padding: 15px !important
    .fw-bold
      font-weight: 600
    .material-icons
      font-size: 3em
      color: #ddd
      cursor: pointer
  .maincard .card-body.border-color-blue
    border-left: 4px solid #0061f2
  .maincard .card-body.border-color-red
    border-left: 4px solid #d92550
  .maincard .card-body.border-color-yullow
    border-left: 4px solid #f7b924
  .maincard .card-body.border-color-green
    border-left: 4px solid #3ac47d

.main-header-input
  margin-top: -5px
  padding-right: 5px
  .customDate
    padding-bottom: 0 !important
    .q-field__inner
      padding: 0 !important
.mainpoplayer
  padding: 6px 6px 6px 6px !important
.anymoreNotice
  position: absolute
  bottom: 10px
  right: 20px
.mainCircle 
  display: flex
  justify-content: center
  padding-top: 10px
  padding-left: 10px
  .circlem
    padding-top: 10px
    width: 28%
  .circleText
    text-align: center
    border-radius: 50%
    font-size: 1.2em
    font-weight: 600
    width: 120px
    height: 120px
    position: relative
    background: #fff
    p
      padding-top: 25px
  .circleText1
    border: 8px solid #82AA35
  .circleText2
    border: 8px solid #38AD85
  .circleText3
    border: 8px solid #329FAA
  .circleTextCenter
    background: #fff
    border-radius: 5px
    margin-top: -60px
    padding-top: 20px
    margin-left: 60px
    margin-right: 10px
    padding-left: 65px
    padding-right: 10px
    font-size: 1.05em
    min-height: 83px
    border: 1px solid #ddd


@-webkit-keyframes fadeInFromNone
  0%
    opacity: 0
  50%
    opacity: 0
  70%
    opacity: 0.5
  100%
    opacity: 1

@-moz-keyframes fadeInFromNone
  0%
    opacity: 0
  50%
    opacity: 0
  70%
    opacity: 0.5
  100%
    opacity: 1

@-o-keyframes fadeInFromNone
  0%
    opacity: 0
  50%
    opacity: 0
  70%
    opacity: 0.5
  100%
    opacity: 1

@keyframes fadeInFromNone
  0%
    opacity: 0
  50%
    opacity: 0
  70%
    opacity: 0.5
  100%
    opacity: 1

</style>
<style scoped>
.widget-numbers {
  font-size: 20px;
}
.impr-item {
  height: 100% !important;
}
.mainMarginTop {
  margin-top: 10px !important;
}
@media screen and (min-width: 1024px) {
	.topmainrow {
		display: block;
	}
}
@media screen and (max-width: 1024px) {
	.topmainrow {
		display: none;
	}
}
</style>